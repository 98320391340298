import React from "react";
import { Image } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

// importacion de los efectos de las cards, se modifica el CSS para darle elpadding
import "../CSS/cardsefects.css";

// importacion de toda la libreria de imagenes
import The_Wolf from "../Assets/img/wolf2.png";
import Clara_Sola from "../Assets/img/Final Clara thumbnail.jpg";
import Molt from "../Assets/img/Molt.png";
import Shelter from "../Assets/img/Shelter.jpg";
import Letting_Go from "../Assets/img/Letting Go.png";
import Asunder from "../Assets/img/Asunder.jpg";
import Filip from "../Assets/img/Filip.jpg";
import Not_Blue from "../Assets/img/Not Blue.png";
import Surrogate_People from "../Assets/img/Surrogate People.png";

const Cardfilm = (props) => {
  return (
    <Container>
      <Row className="montserrat font-weight-bold">
        <Col className="hovereffect" xs={6} md={4}>
          <Button href="../pages/Surrogate" variant="outline-link">
            <Image
              className="hovereffect"
              src={Surrogate_People}
              alt="Surrogate"
            />
            <Card.ImgOverlay className="mt-3 align-items-center justify-content-center">
              <Row>
                <Col className=" mb-n4">
                  <h4>SURROGATE PEOPLE</h4>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h6> Early Development</h6>
                </Col>
              </Row>
            </Card.ImgOverlay>
          </Button>
        </Col>

        <Col className="hovereffect" xs={6} md={4}>
          <Button href="../pages/The_Wolf" variant="outline-link">
            <Image className="hovereffect" src={The_Wolf} alt="The_Wolf" />
            <Card.ImgOverlay className="mt-3 align-items-center justify-content-center">
              <Row>
                <Col className=" mb-n4">
                  <h5>THE WOLF WILL TEAR YOUR IMMACULATE HANDS</h5>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h6>2026</h6>
                </Col>
              </Row>
            </Card.ImgOverlay>
          </Button>
        </Col>

        <Col className="hovereffect" xs={6} md={4}>
          <Button href="../pages/Clara_Sola" variant="outline-link">
            <Image className="hovereffect" src={Clara_Sola} alt="Clara_Sola" />
            <Card.ImgOverlay className="mt-3 align-items-center justify-content-center">
              <Row>
                <Col className=" mb-n3">
                  <h4>CLARA SOLA</h4>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h6>2021</h6>
                </Col>
              </Row>
            </Card.ImgOverlay>
          </Button>
        </Col>

        <Col className="hovereffect" xs={6} md={4}>
          <Button href="../pages/Molt" variant="outline-link">
            <Image className="hovereffect" src={Molt} alt="Molt" />
            <Card.ImgOverlay className="mt-3 align-items-center justify-content-center">
              <Row>
                <Col className=" mb-n3">
                  <h4>MOLT</h4>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h6>2018</h6>
                </Col>
              </Row>
            </Card.ImgOverlay>
          </Button>
        </Col>

        <Col className="hovereffect" xs={6} md={4}>
          <Button href="../pages/Shelter" variant="outline-link">
            <Image className="hovereffect" src={Shelter} alt="Shelter" />
            <Card.ImgOverlay className="mt-3 align-items-center justify-content-center">
              <Row>
                <Col className=" mb-n3">
                  <h4>SHELTER</h4>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h6>2018</h6>
                </Col>
              </Row>
            </Card.ImgOverlay>
          </Button>
        </Col>

        <Col className="hovereffect" xs={6} md={4}>
          <Button href="../pages/Letting_Go" variant="outline-link">
            <Image className="hovereffect" src={Letting_Go} alt="Letting_Go" />
            <Card.ImgOverlay className="mt-3 align-items-center justify-content-center">
              <Row>
                <Col className=" mb-n3">
                  <h4>LETTING GO</h4>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h6>2016</h6>
                </Col>
              </Row>
            </Card.ImgOverlay>
          </Button>
        </Col>

        <Col className="hovereffect" xs={6} md={4}>
          <Button href="../pages/Asunder" variant="outline-link">
            <Image className="hovereffect" src={Asunder} alt="Asunder" />
            <Card.ImgOverlay className="mt-3 align-items-center justify-content-center">
              <Row>
                <Col className=" mb-n3">
                  <h4>ASUNDER</h4>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h6>2015</h6>
                </Col>
              </Row>
            </Card.ImgOverlay>
          </Button>
        </Col>

        <Col className="hovereffect" xs={6} md={4}>
          <Button href="../pages/Filip" variant="outline-link">
            <Image className="hovereffect" src={Filip} alt="Filip" />
            <Card.ImgOverlay className="mt-3 align-items-center justify-content-center">
              <Row>
                <Col className=" mb-n3">
                  <h4>FILIP</h4>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h6>2015</h6>
                </Col>
              </Row>
            </Card.ImgOverlay>
          </Button>
        </Col>

        <Col className=" hovereffect" xs={6} md={4}>
          <Button href="../pages/NotBlue" variant="outline-link">
            <Image className="hovereffect" src={Not_Blue} alt="NotB_lue" />
            <Card.ImgOverlay className="mt-3 align-items-center justify-content-center">
              <Row>
                <Col className=" mb-n3">
                  <h4>NOT BLUE</h4>
                </Col>
              </Row>

              <Row>
                <Col>
                  <h6>2011</h6>
                </Col>
              </Row>
            </Card.ImgOverlay>
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default Cardfilm;
