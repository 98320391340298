import React, { Component } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

class NotBlue extends Component {
  render() {
    return (
      <Container>
        <Row className="p-2"></Row>
        <Row>
          <Col lg={7}>
            <div className=" mb-5 mr-5 embed-responsive embed-responsive-16by9 ">
              <iframe
                title="embedsPage"
                className=" embed-responsive-item"
                src="https://player.vimeo.com/video/79677290?title=0&byline=0&portrait=0"
                allow="autoplay; fullscreen"
                allowfullscreen
              ></iframe>
            </div>
          </Col>

          <Col className="ml-3" lg={4}>
            <Row>
              <h3 className="robotomono font-weight-bold ">NOT BLUE</h3>
              <h6 className="ml-4  robotomono font-weight-bold d-flex align-items-end">
                14 min
              </h6>
            </Row>

            <Row className="mb-5">
              <h6 className="robotomono font-weight-bold sticky-top">
                Writer / Director / Editor
              </h6>
            </Row>

            <Row className="mt-5 montserrat ">
              <p>
                Where do you draw the line in terms of how much you can look
                after someone else? Our characters are mutually interdependent,
                living in a close relationship that is hard to define on the
                basis of stereotypical assumptions.
              </p>
            </Row>
          </Col>
        </Row>

        <Row className="p-5"></Row>
      </Container>
    );
  }
}

export default NotBlue;

/* <div style="padding:56.25% 0 0 0;position:relative;">
    <iframe src="https://player.vimeo.com/video/79677290?title=0&byline=0&portrait=0"
    style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0"
    allow="autoplay; fullscreen" allowfullscreen><
    /iframe></div><script src="https://player.vimeo.com/api/player.js"></script> */
