import React, { Component } from "react";

import Container from "react-bootstrap/Container";
import Figure from "react-bootstrap/Figure";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

// IMPORTACION DE IMAGENES
import insta from "../Assets/img/insta.png";
import mail from "../Assets/img/mail.png";

import Naty from "../Assets/img/nat7.JPG";

class Contact extends Component {
  render() {
    return (
      <Container className=" robotomono font-weight-bold">
        <Row className="py-5">
          <Col className="py-4">
            <Row>
              <Col xs={6} md={6}>
                <Image
                  className="mw-100 mr-5 mb-5"
                  alt="Max-width 100%"
                  src={Naty}
                ></Image>
              </Col>

              <Col
                className=" justify-content-md-center robotomono font-weight-bold"
                bg="white"
                text="cark"
                border="white"
              >
                <Col className="ml-n2" md="auto">
                  <a href="https://www.instagram.com/pelo_suelto_films/">
                    <Figure>
                      <Figure.Image
                        className=""
                        width={45}
                        height={45}
                        alt="10x18"
                        src={insta}
                      />
                    </Figure>
                    <mail className="text-dark ml-n1"> pelo_suelto_films </mail>
                  </a>
                </Col>
                <Col className="" md="auto">
                  <Figure className="m-0">
                    <Figure.Image
                      width={30}
                      height={30}
                      alt="10x18"
                      src={mail}
                      />
                  </Figure>
                  <mail>&nbsp;info@nathaliealvarez.com</mail>
                  <p className="m-0 small font-weight-bold">(Unsolicited materials will be deleted)</p>
                </Col>
                <Col className="mt-4">
                  <u>For work inquiries:</u>
                  <p>
                    Caroline Marques: caroline.p.marques@gmail.com <br />
                    Payne Cowley: pcowley@rangemp.com
                  </p>
                </Col>
                <Col>
                  <u>For Clara Sola inquiries:</u>
                  <p>fiorella@luxboxfilms.com</p>
                </Col>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="p-5"></Row>
      </Container>
    );
  }
}

export default Contact;
