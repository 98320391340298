import React, { Component } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";

import TheWolf from "../Assets/img/wolf2.png";

class The_Wolf extends Component {
  render() {
    return (
      <Container>
        <Row className="p-2"></Row>
        <Row>
          <Col lg={7}>
            <div className="mb-5 mr-5  embed-responsive embed-responsive-16by9 ">
              <Image
                title="embedsPage"
                className=" embed-responsive-item"
                src={TheWolf}
                allow="autoplay; fullscreen"
                allowfullscreen
              ></Image>
            </div>
          </Col>

          <Col className="ml-3" lg={4}>
            <Row>
              <h3 className="robotomono font-weight-bold ">
                THE WOLF WILL TEAR YOUR IMMACULATE HANDS
              </h3>
              <h6 className=" robotomono font-weight-bold "> </h6>
            </Row>
            <Row>
              <h6 className="robotomono font-weight-bold sticky-top">
                FEATURE Co-written by Sjón
              </h6>
            </Row>

            <Row className="mb-5">
              <h6 className="robotomono font-weight-bold sticky-top">
                2026
              </h6>
            </Row>

            <Row className=" montserrat mt-5  mr-2">
              <p>
                Set in the 1860s in the remote North Pacific West of the U.S., 
                the story follows Isabel, a Native American woman who, educated 
                at a Christian mission school, grapples with her loss of heritage. 
                Now, she begins her position as a governess for the daughters of 
                a British widower. As the arranged marriage of the eldest daughter 
                looms closer, tensions rise within the household, uncovering 
                a mystical bond between the women and the forest surrounding 
                them—a connection the girls’ father is desperate to break.
              </p>
            </Row>
          </Col>
        </Row>
        <Row className="p-5"></Row>
      </Container>
    );
  }
}

export default The_Wolf;
