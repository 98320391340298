import React, { Component } from "react";

import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";

import Naty from "../Assets/img/Nathalie still_ cred Lina Tegman.jpg";

class Bio extends Component {
  render() {
    return (
      <Container>
        <Row className="pt-4"></Row>
        <Row>
          <Col>
            <Image
              className="mw-100 mt-4"
              alt="Max-width 100%"
              src={Naty}
            ></Image>
          </Col>

          <Col className="montserrat" sm={8}>
            <Alert>
              <p>
                Nathalie Álvarez Mesén is a Costa Rican-Swedish screenwriter/director.
                Her debut feature film CLARA SOLA premiered in Cannes 2021 in the
                Directors’ Fortnight section.
              </p>
              <p>
                She started her career in physical theatre in Costa Rica before pursuing her
                B.F.A. degree in Mime Acting at the Stockholm University of the Arts in
                Sweden. Nathalie later graduated from Columbia University’s Graduate
                Film Program in NYC with an M.F.A. in Film Directing/Screenwriting.
              </p>
              <p>
                An alumna of the Berlinale Talents, TIFF Filmmaker Lab and NYFF
                Artist Academy, Nathalie’s shorts have screened at film festivals all over
                the world. Her short, FILIP, won Best Film Under 15 Minutes at the 2016
                Palm Springs Shortfest, and ASUNDER, screened at the 2016 Telluride
                Film Festival as part of the Calling Cards program.
              </p>
              <p>
                Nathalie also co-wrote ENTRE TÚ Y MILAGROS, winner of the Orizzonti Award 
                for Best Short in the Venice Film Festival 2020. Recently, she also directed 
                an episode for Showtime’s “Three Women”, premiering late 2024. Now she set 
                to direct her second feature, THE WOLF WILL TEAR YOUR IMMACULATE HANDS, 
                which she co-wrote with Sjón. The shoot is set to start in 2025.
              </p>
              <hr />
              <p className="mb-0"></p>
            </Alert>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Bio;
