import React from "react";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Figure from "react-bootstrap/Figure";
import "../CSS/fonts.css";

import { Link } from "react-router-dom";

import imdb from "../Assets/img/imdb.png";
import vimeo from "../Assets/img/vimeo.png";

function Menu() {
  return (
    <Navbar variant="light" className="mx-3 my-3" bg="white" expand="lg">
      <div>
        <h3 className="robotomono font-weight-bold" href="#home">
          Nathalie Álvarez Mesén
        </h3>
        <h6 className="robotomono d-flex justify-content-end ">
          Writer / Director
        </h6>
      </div>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse
        className=" justify-content-end p-2"
        id="basic-navbar-nav"
      >
        <h5>
          <Nav className="pb-4 robotomono font-weight-bold ">
            <Nav.Link
              as={Link}
              href="Bio"
              style={{ textDecoration: "none" }}
              to={"/pages/Bio"}
            >
              Bio
            </Nav.Link>
            <Nav.Link
              as={Link}
              href="#home"
              style={{ textDecoration: "none" }}
              to={"/"}
            >
              Films
            </Nav.Link>
            <Nav.Link
              as={Link}
              href="Contact"
              style={{ textDecoration: "none" }}
              to={"../pages/Contact"}
            >
              Contact
            </Nav.Link>
          </Nav>
        </h5>
      </Navbar.Collapse>

      {/* IMDB */}
      <Nav>
        <Figure className="justify-content-end">
          <a href="https://www.imdb.com/name/nm4146381/?ref_=nv_sr_srsg_0">
            <Figure.Image width={55} height={55} src={imdb} />
          </a>
        </Figure>
        <p> </p>

        {/* VIMEOs */}
      </Nav>
      <Nav>
        <Figure className="justify-content-end">
          <a href="https://vimeo.com/user5169165">
            <Figure.Image width={41} height={41} src={vimeo} />
          </a>
        </Figure>
        <p> </p>
      </Nav>
    </Navbar>
  );
}

export default Menu;
