import React, { Component } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Shelter2 from "../Assets/img/Shelter2.png";

class Shelter extends Component {
  render() {
    return (
      <Container>
        <Row className="p-2"></Row>
        <Row>
          <Col lg={7}>
            <div className=" mb-5 mr-5 embed-responsive embed-responsive-16by9 ">
              <Image
                title="embedsPage"
                className=" embed-responsive-item"
                src={Shelter2}
                allow="autoplay; fullscreen"
                allowfullscreen
              ></Image>
            </div>
          </Col>

          <Col className="ml-3" lg={4}>
            <Row>
              <h3 className="robotomono font-weight-bold ">SHELTER</h3>
              <h6 className="ml-4  robotomono font-weight-bold d-flex align-items-end">
                9 min
              </h6>
            </Row>
            <Row className="mb-5">
              <h6 className="robotomono font-weight-bold sticky-top">
                Writer / Director / Editor
              </h6>
            </Row>

            <Row className="montserrat mt-5  mr-2">
              <p>
                A young father, takes a late night journey through the streets
                of New York City with his daughter to flee the toxic environment
                she lives in. Afraid to make a meaningful connection, he is
                faced with the ultimate decision of keeping her or not.
              </p>
            </Row>
          </Col>
        </Row>

        <Row className="p-5"></Row>
      </Container>
    );
  }
}

export default Shelter;
