import React, { Component } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

class Letting_Go extends Component {
  render() {
    return (
      <Container>
        <Row className="p-2"></Row>
        <Row>
          <Col lg={7}>
            <div className=" mb-5 mr-5 embed-responsive embed-responsive-16by9 ">
              <iframe
                title="embedsPage"
                className=" embed-responsive-item"
                src="https://player.vimeo.com/video/392987143?autoplay=1&color=ffffff&title=0&byline=0&portrait=0"
                allow=" fullscreen"
                allowfullscreen
              ></iframe>
            </div>
          </Col>

          <Col className="ml-3" lg={4}>
            <Row>
              <h3 className="robotomono font-weight-bold ">LETTING GO</h3>
              <h6 className="ml-4  robotomono font-weight-bold d-flex align-items-end">
                4 min
              </h6>
            </Row>
            <Row className="mb-5">
              <h6 className="robotomono font-weight-bold sticky-top">
                Writer / Director / Editor
              </h6>
            </Row>

            <Row className="montserrat mt-5  mr-2">
              <p>
                Sanna is forced to take care of her little brother when their
                mother is emotionally unavailable.
              </p>
            </Row>
          </Col>
        </Row>

        <Row className="p-5"></Row>
      </Container>
    );
  }
}

export default Letting_Go;
