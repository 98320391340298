import React, { Component } from "react";
import Cardfilm from "../Components/card_film";

class Films extends Component {
  render() {
    return <Cardfilm />;
  }
}

export default Films;
