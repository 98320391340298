import React, { Component } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";

import Clara_Sola from "../Assets/img/Final Clara logo.jpg";

class ClaraSola extends Component {
  render() {
    return (
      <Container>
        <Row className="p-2"></Row>
        <Row>
          <Col>
            <div className=" mb-5 mr-5 embed-responsive embed-responsive-16by9 ">
              <Image
                title="embedsPage"
                className=" embed-responsive-item"
                src={Clara_Sola}
                allow="autoplay; fullscreen"
                allowfullscreen
              ></Image>
            </div>
          </Col>

          <Col className="ml-3" lg={"auto"}>
            <Row>
              <h3 className="robotomono font-weight-bold ">CLARA SOLA </h3>
            </Row>
            <Row>
              <p className="robotomono font-weight-bold sticky-top">
                Director / Co-Writer
              </p>
            </Row>
            <Row className="mb-5">
              <h6 className="robotomono font-weight-bold sticky-top">
                FEATURE
              </h6>
            </Row>

            <Row className=" montserrat mt-5  mr-2">
              <p>
                CLARA, 40, is believed to have a special connection to God. As a
                "healer", she sustains a family and a village in need of hope,
                while she finds solace in her relationship with the natural
                world. After years of being controlled by her mother’s
                repressive care, Clara’s sexual desires are stirred by her
                attraction to her niece’s new boyfriend. This newly awakened
                force takes Clara to unexplored territory, allowing her to cross
                boundaries, both physical and mystical. Empowered by her
                self-discovery, Clara gradually frees herself from her role as
                “saint” and begins to heal herself.
              </p>
            </Row>
          </Col>
        </Row>
        <Row className="montserrat font-italic mt-4">
          <Col>
            <p>
              Selected for IFP No Borders International Co-production Market,
              San Sebastian Film Festival’s Co-Production Forum, Film London’s
              PFM Micro Market, Ventana Sur, Mome Finance Lab, Guadalajara
              co-production meeting, Eave Puentes, Equinoxe Screenwriting lab,
              the Latino Screenwriting Project and Cine Qua Non Lab. Backed by
              the Swedish Film Institute, Hubert Bals Fund and the World Cinema
              Fund.
            </p>
          </Col>
        </Row>
        <a href="http://hobab.se/portfolio/clarasola/">
          <p className="montserrat text-muted font-weight-bold">More Info</p>
        </a>

        <Row className="p-5"></Row>
      </Container>
    );
  }
}

export default ClaraSola;
