import React, { Component } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";

import SurrogatePeople from "../Assets/img/Surrogate People.png";

class Surrogate extends Component {
  render() {
    return (
      <Container>
        <Row className="p-2"></Row>
        <Row>
          <Col lg={7}>
            <div className=" mb-5 mr-5 embed-responsive embed-responsive-16by9 ">
              <Image
                title="embedsPage"
                className=" embed-responsive-item"
                src={SurrogatePeople}
                allow="autoplay; fullscreen"
                allowfullscreen
              ></Image>
            </div>
          </Col>

          <Col className="ml-3" lg={4}>
            <Row>
              <h3 className="robotomono font-weight-bold ">SURROGATE PEOPLE</h3>
            </Row>
            <Row>
              <h6 className="robotomono font-weight-bold sticky-top">
                FEATURE
              </h6>
            </Row>
            <Row className="mb-5">
              <h6 className="robotomono font-weight-bold sticky-top">
                In Development
              </h6>
            </Row>

            <Row className=" montserrat mt-5  mr-2">
              <p>
                When 14-year-old Sabina loses her best friend to a Fourth of
                July accident, she struggles to move on while slowly becoming a
                part of his bereaved mother’s life.
              </p>
            </Row>
          </Col>
        </Row>
        <Row className="montserrat font-italic mt-4">
          <Col>
            <p>Faculty Selects 2019, Columbia University Film Festival</p>
          </Col>
        </Row>
        <Row className="p-5"></Row>
      </Container>
    );
  }
}

export default Surrogate;
