import React from "react";
import { Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./CSS/fonts.css";
import Menu from "./Components/menu";
// import Footer from './Components/footer';

import Films from "./pages/Films";
import Bio from "./pages/Bio";
import Contact from "./pages/Contact";
import NotBlue from "./pages/NotBlue";
import Filip from "./pages/Filip";
import Asunder from "./pages/Asunder";
import Letting_Go from "./pages/Letting_Go";
import Shelter from "./pages/Shelter";
import Molt from "./pages/Molt";
import Clara_Sola from "./pages/Clara_Sola";
import The_Wolf from "./pages/The_Wolf";
import Surrogate from "./pages/Surrogate";

function App() {
  return (
    <div>
      <Menu />

      <section>
        <Switch>
          <Route path="/pages/Bio" component={Bio} />
          <Route exact path="/" component={Films} />
          <Route path="/pages/Contact" component={Contact} />
          <Route path="/pages/NotBlue" component={NotBlue} />
          <Route path="/pages/Filip" component={Filip} />
          <Route path="/pages/Asunder" component={Asunder} />
          <Route path="/pages/Letting_Go" component={Letting_Go} />
          <Route path="/pages/Shelter" component={Shelter} />
          <Route path="/pages/Molt" component={Molt} />
          <Route path="/pages/Clara_Sola" component={Clara_Sola} />
          <Route path="/pages/The_Wolf" component={The_Wolf} />
          <Route path="/pages/Surrogate" component={Surrogate} />
        </Switch>
      </section>

      {/* <Footer /> */}
    </div>
  );
}

export default App;
